<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="ml-2"
        min-width="0"
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

    <v-list
      :tile="false"
      flat
      nav
    >
      <template v-for="(p, i) in profile">
        <v-divider
          v-if="p.divider"
          :key="`divider-${i}`"
          class="mb-2 mt-2"
        />
        <app-bar-item
          v-else-if="p.logoutx"
          :key="`logout-${i}`"
        >
          <v-list-item-title
            @click="logout"
            v-text="p.title"
          />
        </app-bar-item>
        <app-bar-item
          v-else
          :key="`item-${i}`"
          :to="p.path"
        >
          <v-list-item-title v-text="p.title" />
        </app-bar-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
  const fb = require('../../../firebaseConfig.js')
  export default {
    name: 'DefaultAccount',

    data: () => ({
      profile: [
        { title: 'Xувийн мэдээлэл', path: '/components/profile/' },
        { title: 'Тоxируулга', path: '/components/mysetting/' },
        { divider: true },
        { title: 'Гараx', logoutx: true },
      ],
    }),
    methods: {
      logout() {
        fb.auth.signOut().then(() => {
          this.$store.dispatch('clearData')
          this.$router.push('/')
        }).catch(err => {
          console.log(err)
        })
      },
    },
  }
</script>
